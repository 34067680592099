<template>
  <ftx-dialog
    :dialog="dialog"
    position="standard"
    :maximized="false"
    :persistent="true"
  >
    <template v-slot:DialogContent>
      <q-card class="simpleDialog q-pa-none">
        <q-card-section :class="isMobile ? 'q-px-md q-pb-md' : 'q-pa-xl'">
          <div class="container">
            <div class="row q-col-gutter-md justify-center">
              <div class="col-12 text-center">
                <SvgIcon size="5rem" icon="save-identity" />
              </div>
              <div class="col-12 text-center">
                <div
                  class="text-weight-bold text-grey-9 q-mb-md"
                  :class="isMobile ? 'text-h6' : 'text-h5'"
                  >Do you want to save your age-verified profile for future
                  vists?</div
                >
                <div class="text-body1 text-dark">
                  Use <strong>"Sign Up"</strong> or
                  <strong>"Sign In"</strong> to save your age-verified profile
                  for future visits, as age verification is temporary for a
                  visit. You may <strong>skip</strong> this step if you are okay
                  with being verified again upon future visits.
                </div>
                <div class="row q-col-gutter-md q-mt-lg">
                  <div class="col-6 col-md-6">
                    <div class="form-action pb-0">
                      <q-btn
                        unelevated
                        no-caps
                        color="secondaryOnBody"
                        label="Sign Up"
                        class="q-btn-lg full-width"
                        @click="loginWithIdentity(true, ' aat:signup')"
                      />
                    </div>
                  </div>
                  <div class="col-6 col-md-6">
                    <div class="form-action pb-0">
                      <q-btn
                        unelevated
                        no-caps
                        outline
                        color="tertiary"
                        label="Sign In"
                        class="q-btn-lg full-width"
                        @click="loginWithIdentity(false)"
                      />
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-action pb-0">
                      <q-btn
                        unelevated
                        no-caps
                        flat
                        color="secondaryOnBody"
                        label="Skip, I'm ok to be verified again"
                        padding="4px 15px"
                        class="text-underline no-hover"
                        :class="$q.screen.lt.md ? '' : 'q-btn-md'"
                        v-close-popup
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </q-card-section>
      </q-card>
    </template>
  </ftx-dialog>
</template>

<script>
export default {
  name: 'AskForSignup',
  data() {
    return {
      dialog: 'AskForSignup',
    }
  },
}
</script>

<style lang="scss"></style>
